.expandable-tag {
	position: absolute;
	bottom: 20px; /* Adjust to position the tag at the bottom of the row */
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	border-radius: 5px;
	padding: 5px;
	cursor: pointer;
	transition: width 0.3s;
	overflow: hidden;
}

	.expandable-tag.expanded {
	/*	width: 420px; *//* Adjust based on your requirements */
		height: 25px;
	}

.tag-content {
	display: flex;
	align-items: center;
	height:15px;
}

/*.audio-icon {
	font-size: 24px;
	padding-left: 8px;
}*/

.inner-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

/*.collapse-icon {
	margin-left: 10px;
	font-size: 18px;
	cursor: pointer;
}*/
