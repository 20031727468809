.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


body {
    border-top-width: 0;
    /*padding: 30px 30px 5px;*/
    text-align: center;
    color: white !important;
    background: url(images/gradient4.png) top center/cover no-repeat fixed,#171717 url(images/bg-grain.png) top left repeat;
}

.bg-yellow {
    background: url(images/gradient3.png) top center/cover no-repeat fixed,#171717 url(images/bg-grain.png) top left repeat;
}

.bg-dr {
    background: url(images/gradient6.png) top center/cover no-repeat fixed,#171717 url(images/bg-grain.png) top left repeat;
    color: white !important;
}

.bg-ey {
    background: url(images/gradient4.png) top center/cover no-repeat fixed,#171717 url(images/bg-grain.png) top left repeat;
    color: black;
}

.bg-mob {
    background: url(images/mutantsMOB33.jpg) top center/cover no-repeat,#171717 url(images/bg-grain.png) top left repeat;
}

.nav-item-custom {
    font-weight: bold;
    color: white !important;
}

    .nav-item-custom:hover {
        font-weight: bold;
        color: #ffba00 !important;
    }

.dropdown-item-custom {
    font-weight: bold !important;
}

    .dropdown-item-custom:hover {
        background-color: #ffba00 !important;
    }

/*.dropdown-toggle::after {
    font-size: 1.5em;*/ /* Adjust this value to make the arrow bigger */

/*}*/


.bg-transparent {
    background-color: transparent !important;
}

@media (min-width: 1200px) {
    .container {
        max-width: 85%;
        min-width: 75%;
    }
}


/*
.nav-item-group {
    padding: 8px;
    border-radius: 5px;
    align-items: center;
    border: 2px solid lightsteelblue;
}*/


.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px; /* Set a fixed height for the carousel items */
}

    .carousel-item img {
        max-height: 100%; /* Ensure images do not exceed the height of the carousel item */
        max-width: 100%; /* Ensure images do not exceed the width of the carousel item */
        object-fit: contain; /* Ensure the images maintain their aspect ratio */
    }

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5%; /* Adjust as needed */
}

.carousel-control-prev {
    left: -30px; /* Move the control further left */
    margin-top: 180px;
}

.carousel-control-next {
    right: -30px; /* Move the control further right */
    margin-top: 180px;
}



.tags-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 18%;
}

.tag-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Black background */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: 25px;
    height: 25px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

    .tag-link:hover {
        text-decoration: none;
    }

    .tag-link i {
        color: #fff; /* White icon color */
        transition: color 0.3s;
    }

    .tag-link:hover i {
        color: orange; /* Orange icon color on hover */
    }


/*Contact Us Link*/
.contact-us-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 85%;
}

.contact-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Black background */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    width: 100px;
    height: 25px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    color: white;
}

    .contact-link:hover {
        color: orange; /* White text on hover */
        text-decoration: none;
    }


/*.tags-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 18%;
}

.tag-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;*/ /* Black background */
/*border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    width: 25px;
    height: 25px;
    text-decoration: none;
    color: #fff;*/ /* White icon color */
/*transition: background-color 0.3s, color 0.3s;
}

    .tag-link i {
        color: #fff;*/ /* White icon color */
/*}

    .tag-link:hover {
        background-color: orange;*/ /* Orange background on hover */
/*color: #000;*/ /* Black text on hover */
/*}

        .tag-link:hover i {
            color: #000;*/ /* Black icon color on hover */
/*}*/
/*#httpLogViewer, #techieBuddyAIComponent, #tgBotPlateComponent,
#tgVpnBotComponent, #psmComponent {
    position: sticky;
    top: 0;
    background-color: #fff;*/ /* Adjust background color as needed */
/*z-index: 100;*/ /* Ensure it's above other content */
/*padding: 0px 0;*/ /* Adjust padding as needed */
/*}*/


/* Custom styles for carousel controls on white background */
.carousel-control-prev-icon-dark,
.carousel-control-next-icon-dark {
    background-color: black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.carousel-control-prev-icon-dark {
    background-image: none;
}

.carousel-control-next-icon-dark {
    background-image: none;
}

.carousel-indicators-dark li {
    background-color: black;
}

.carousel-indicators-dark .active {
    background-color: red; /* Change this to the desired active color */
}

.invert {
    filter: invert(100%);
}


.slick-dots li button {
    filter: invert(100%);
}



/* Keyframes for pulsating border effect */
@keyframes pulseBorder {
    0% {
        transform: scale(1) rotate(12deg);
        border-width: 2px;
        border-color: rgba(255, 0, 0, 0.5);
    }

    50% {
        transform: scale(1.1) rotate(12deg);
        border-width: 6px;
        border-color: rgba(0, 255, 0, 0.7);
    }

    100% {
        transform: scale(1) rotate(12deg);
        border-width: 2px;
        border-color: rgba(0, 0, 255, 0.5);
    }
}


.sound-visualization {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 50%; /* Make it circular if your PNG is circular */
    animation: pulseBorder 10s infinite;
}

    .sound-visualization img {
        display: block;
        max-width: 100%;
        border-radius: inherit; /* Ensure the image matches the border-radius of the container */
    }


.image-container {
    position: relative;
}

.shaking-image {
    animation: shake-pause 5s infinite;
}

@keyframes shake-pause {
    0%, 2%, 4%, 6%, 8%, 10%, 12%, 14%, 16%, 18% {
        transform: translateX(-8px);
    }

    1%, 3%, 5%, 6%, 7%, 9%, 11%, 13%, 15%, 17% {
        transform: translateX(8px);
    }

    19%, 100% {
        transform: translateX(0);
    }
}


/*@keyframes shake-pause {
    0%, 2.5%, 7.5%, 12.5%, 17.5%, 22.5% {
        transform: translateX(-10px);
    }

    5%, 10%, 15%, 20% {
        transform: translateX(10px);
    }

    25%, 100% {
        transform: translateX(0);
    }
}*/
