.youtube-control {
    background-color: black;
    color: #00ff00; /* Green text color */
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ticker-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.ticker-content {
    animation: ticker 25s linear infinite;
    animation-play-state: running;
    color: #00ff00; /* Green text color */
}

@keyframes ticker {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.paused .ticker-content {
    animation-play-state: paused;
}

.ticker-button {
    display: flex;
    align-items: center;
}

    .ticker-button span {
        margin-left: 10px;
        color: #00ff00; /* Green text color */
        cursor: pointer;
        font-size: 14px;
    }

        .ticker-button span:hover {
            color: #00ff00; /* Green text color */
        }

.select-wrapper {
    position: relative;
}

    .select-wrapper select {
        background-color: black;
        color: #00ff00; /* Green text color */
        border: none;
        padding: 5px;
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        cursor: pointer;
    }

.coin-image {
    max-height: 20px;
    max-width: 20px;
    margin-right: 5px;
    vertical-align: middle;
}
