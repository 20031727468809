/* Ticker.css */
.ticker-container {
    background-color: black;
    color: #00FF00;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
}

.ticker-content {
    display: inline-block;
/*    padding-right: 100%;*/
    animation: ticker 25s linear infinite;
    animation-play-state: running;
    white-space: nowrap;
}

@keyframes ticker {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.paused .ticker-content {
    animation-play-state: paused;
}

.ticker-button {
    margin-left: 10px;
    color: #00FF00;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 10px;
}

    .ticker-button:hover {
        color: #00FF00;
    }


.coin-image {
    max-height: 20px; /* Adjust height as needed */
    max-width: 20px; /* Adjust width as needed */
    margin-right: 5px; /* Adjust margin as needed for spacing */
    vertical-align: middle; /* Aligns image vertically with text */
}
